<template>
  <div>
    <!-- 관련설비 목록 -->
    <c-table
      ref="table"
      title="LBL0002008"
      tableId="related-equip"
      :columns="grid.columns"
      :data="moc.equipments"
      :gridHeight="setGridHeight"
      :editable="editable&&!disabled"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isUpdate"
            :url="updateUrl"
            :param="moc.equipments"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveEquip"
            @btnCallback="saveEquipCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'moc-facility-restoration',
  components: {
  },
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
        mocTitle: '',  // 변경관리 제목
        prestartupImproves: [],
        improves: [],
        imprImmData: [],
        imprData: [],
        equipments: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    height: {
      type: String,
      default: function() {
        return '700px';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            // 설비코드
            label: 'LBL0002011',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'link',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0000632',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 설비유형
            label: 'LBL0000633',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'restorationFlag',
            field: 'restorationFlag',
            // 원복여부
            label: 'LBL0002010',
            align: 'center',
            sortable: true,
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'processingHistory',
            field: 'processingHistory',
            // 처리내역
            label: 'LBL0002009',
            align: 'left',
            sortable: true,
            style: 'width:500px',
            type: 'textarea',
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      updateUrl: '',
      isUpdate: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setGridHeight() {
      return this.height ? this.height : 'auto'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.updateUrl = transactionConfig.sop.moc.restorationEquipment.update.url
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL0000955'; // 설비 상세
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveEquip() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGSAVE', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isUpdate = !this.isUpdate
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveEquipCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 상세 조회
      this.$emit('saveCallback', this.moc.sopMocId)
    },
  }
};
</script>